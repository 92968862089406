import logger from 'config/logger';
import productFetchStatusSlice from 'stores/reducers/product-fetch-status';

export const setFetchStatus = (status) => (dispatch) => {
  try {
    dispatch(productFetchStatusSlice.actions.setProductFetchStatus(status));
  } catch (err) {
    logger.error(err);
  }
};

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import qs from 'qs';
import logger from 'config/logger';
import { RequestInstance } from 'request';
import { setFetchStatus } from 'stores/actions/product-fetch-status';

async function getDiscountTypeMap({ store, filters }) {
  try {
    const discountMap = await RequestInstance.get(
      'api/discounts/discount-type-map',
      { searchParams: qs.stringify({ store, filters }) }
    );
    return discountMap ?? {};
  } catch (err) {
    logger.error(
      err,
      `Error retrieving discount type map for location: ${store}`
    );
    return {};
  }
}

function useGetDiscountTypeMap({ propTypeMap, store }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const storeSelection = useSelector((state) => state.storeSelection);
  const [typeMap, setTypeMap] = useState(propTypeMap);
  const storedLocation = useRef(store);
  const prevUrl = useRef(router.asPath);
  const initialFiltersRefresh = useRef(router.query?.filters);

  const setDiscountsLoadingStatus = async (status) => {
    await dispatch(setFetchStatus(status));
  };

  useEffect(() => {
    const get = () => {
      setDiscountsLoadingStatus('LOADING')
        .then(() => {
          getDiscountTypeMap({
            store: storeSelection,
            filters: router.query.filters,
          })
            .then((tm) => {
              const { discountMap } = tm;
              setTypeMap(discountMap);
            })
            .catch(() => {})
            .finally(() => setDiscountsLoadingStatus('FINISHED'));
        })
        .catch(() => setDiscountsLoadingStatus('FINISHED'));
    };

    if (storeSelection && storeSelection !== storedLocation.current) {
      storedLocation.current = storeSelection;
      get();
    }

    if (initialFiltersRefresh.current) {
      initialFiltersRefresh.current = undefined;
      get();
    }

    const url = router.asPath;
    if (prevUrl.current !== url) {
      get();
      prevUrl.current = url;
    }
  }, [storeSelection, router]);

  useEffect(() => {
    setTypeMap(propTypeMap);
  }, [propTypeMap]);

  return typeMap;
}
export default useGetDiscountTypeMap;

import classNames from 'classnames';
import DiscountCardList from 'components/DiscountCardList';
import Link from 'components/Link';
import ScrollArrows from 'components/ScrollArrows';
import codify from 'utils/codify';
import styles from './DiscountRowSection.module.scss';

const DISPLAY_DISCOUNT_LIMIT = 6;

export default function DiscountRowSection({ discounts, sectionContent }) {
  const {
    lead_in: leadIn,
    header,
    subheader,
    cta_one: { title: ctaText, href: ctaHref },
    default_image: defaultImage,
    view_all_image: viewAllImage,
    view_all_title: viewAllTitle,
  } = sectionContent;

  const formattedHeader = codify(header);
  const containerId = `${formattedHeader}_discount_container`;
  const leftButtonId = `${formattedHeader}_left_button`;
  const rightButtonId = `${formattedHeader}_right_button`;

  const displayDiscounts =
    discounts?.length > DISPLAY_DISCOUNT_LIMIT
      ? [
          ...discounts.slice(0, DISPLAY_DISCOUNT_LIMIT),
          {
            id: 1,
            image: viewAllImage || defaultImage,
            onlineName: viewAllTitle || 'View All',
            onlineNameOverride: viewAllTitle || 'View All',
            isViewAll: true,
          },
        ]
      : discounts;

  return (
    <section className={classNames('mx-auto my-10 w-100', styles.section)}>
      <div className="d-flex flex-row justify-content-between mx-8 pb-14">
        <div className="me-8 font-acumin">
          {leadIn && (
            <p className="fw-900 text-primary fs-7 fs-xl-9 mb-4">{leadIn}</p>
          )}
          {header && (
            <h2 className="fw-900 text-primary fs-15 fs-xl-26 ">{header}</h2>
          )}
          {subheader && (
            <p className="fw-900 text-primary fs-7 fs-xl-9 mb-4">{subheader}</p>
          )}
        </div>
        {ctaText && ctaHref && (
          <Link
            href={ctaHref}
            className={classNames(
              styles.view_all,
              'btn bg-white border-2 border-primary d-inline-block font-geo fs-7 fw-600 px-10 py-5 my-auto text-primary text-uppercase'
            )}
          >
            {`${ctaText} (${discounts?.length ?? 0})`}
          </Link>
        )}
      </div>
      <ScrollArrows
        containerId={containerId}
        leftButtonId={leftButtonId}
        rightButtonId={rightButtonId}
        scrollBuffer={5}
        scrollIncrement={300}
      >
        <DiscountCardList
          horizontal
          includeViewAllCard
          id={containerId}
          discounts={displayDiscounts}
          sectionContent={sectionContent}
        />
      </ScrollArrows>
    </section>
  );
}

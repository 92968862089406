import classNames from 'classnames';
import Image from 'components/Image';
import Link from 'components/Link';
import styles from './DiscountCardList.module.scss';

export default function DiscountCardList({
  id,
  discounts,
  horizontal,
  sectionContent,
  useCustomGrid = false,
}) {
  const { default_image: defaultImage, url: baseUrl } = sectionContent;

  const numberOfDiscounts = discounts?.length ?? 0;

  const customGridWidth =
    useCustomGrid && !horizontal
      ? numberOfDiscounts === 1
        ? styles.grid_one_wide
        : numberOfDiscounts === 2
        ? styles.grid_two_wide
        : numberOfDiscounts === 3
        ? styles.grid_three_wide
        : numberOfDiscounts === 4
        ? styles.grid_four_wide
        : ''
      : '';

  return (
    <ul
      id={id}
      className={classNames(
        'list-unstyled m-5',
        `${
          horizontal
            ? styles.discounts_container_horizontal_scroll
            : styles.discounts_container
        }`,
        customGridWidth
      )}
    >
      {discounts.map((discount) => {
        const { id, onlineName, onlineNameOverride, image, isViewAll, slug } =
          discount;
        const url = isViewAll
          ? baseUrl
          : `${baseUrl}/${id}?filters=${encodeURIComponent(
              'Special^term^' + slug
            )}`;
        return (
          <li key={image?.alt ?? onlineName} className="p-0">
            <Link href={url}>
              <div className="d-flex flex-column h-100 mx-2">
                <div
                  className={classNames(
                    'mw-100 mx-auto mt-1 position-relative',
                    styles.discount_image_container
                  )}
                >
                  <div
                    className={classNames(
                      'h-100 w-100 position-absolute',
                      styles.discount_image_wrapper
                    )}
                  >
                    <Image
                      fill
                      legacy
                      className="mw-100 rounded"
                      objectFit="contain"
                      src={image?.url ?? defaultImage?.url}
                      alt={image?.alt ?? defaultImage?.alt}
                      sizes="(max-width: 992px) 50vw, 25vw"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column w-100 my-5 font-acumin">
                  <p className="font-acumin fw-600 my-0 fs-7 fs-md-9">
                    {onlineNameOverride === ''
                      ? onlineName
                      : onlineNameOverride}
                  </p>
                </div>
              </div>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

import { useEffect } from 'react';

const useScrollButtonClicks = ({
  containerId = '',
  disableArrowClass = '',
  leftButtonId = '',
  rightButtonId = '',
  scrollBuffer = 0,
  scrollIncrement = 125,
}) => {
  const disableButton = (buttonId) => {
    const scrollButton = document.querySelector(buttonId);
    if (!scrollButton || !disableArrowClass) return;
    scrollButton.classList.add(disableArrowClass);
  };

  const enableButton = (buttonId) => {
    const scrollButton = document.querySelector(buttonId);
    if (!scrollButton || !disableArrowClass) return;
    scrollButton.classList.remove(disableArrowClass);
  };

  const checkScrollPosition = () => {
    const container = document.querySelector(containerId);
    if (!container) return;
    const { offsetWidth, scrollLeft, scrollWidth } = container;

    const disableLeft = scrollLeft === 0;
    const disableRight =
      scrollWidth - offsetWidth - scrollLeft - scrollBuffer <= 0;

    if (disableLeft) {
      disableButton(leftButtonId);
    } else {
      enableButton(leftButtonId);
    }

    if (disableRight) {
      disableButton(rightButtonId);
    } else {
      enableButton(rightButtonId);
    }
  };

  const scrollRight = () => {
    const container = document.querySelector(containerId);
    if (!container) return;
    container.scrollLeft -= scrollIncrement;
  };

  const scrollLeft = () => {
    const container = document.querySelector(containerId);
    if (!container) return;
    container.scrollLeft += scrollIncrement;
  };
  useEffect(() => {
    const handleScrollEvent = () => {
      checkScrollPosition();
    };

    const handleResizeEvent = () => {
      checkScrollPosition();
    };

    window.addEventListener('resize', handleResizeEvent);
    document
      .querySelector(containerId)
      .addEventListener('scroll', handleScrollEvent);

    checkScrollPosition();

    return () => {
      window.removeEventListener('resize', handleResizeEvent);
      if (document.querySelector(containerId)) {
        document
          .querySelector(containerId)
          .removeEventListener('scroll', handleScrollEvent);
      }
    };
  }, [containerId]);

  return { scrollRight, scrollLeft };
};

export default useScrollButtonClicks;

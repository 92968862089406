import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import useScrollButtonClicks from 'hooks/useScrollButtonClicks';
import styles from './ScrollArrows.module.scss';

export default function ScrollArrows({
  containerId,
  leftButtonId,
  rightButtonId,
  scrollBuffer,
  scrollIncrement,
  disableType = 'hide',
  children,
}) {
  const disableArrowClass =
    disableType === 'hide' ? styles.hidden : styles.disabled;

  const { scrollRight, scrollLeft } = useScrollButtonClicks({
    containerId: `#${containerId}`,
    leftButtonId: `#${leftButtonId}`,
    rightButtonId: `#${rightButtonId}`,
    scrollBuffer,
    scrollIncrement,
    disableArrowClass,
  });

  return (
    <section className="w-100">
      <div
        className={classNames(
          'd-flex flex-row justify-content-between  mx-auto'
        )}
      >
        <Button
          className="bg-transparent text-primary fs-9 m-2 px-0"
          id={leftButtonId}
          type="button"
          title="scroll right"
          onClick={scrollRight}
        >
          <span className={classNames('d-inline-block', styles.arrow)}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
        </Button>
        <div className="w-100 overflow-hidden">{children}</div>
        <Button
          className="bg-transparent text-primary fs-9 m-2 px-0"
          id={rightButtonId}
          title="scroll left"
          type="button"
          onClick={scrollLeft}
        >
          <span className={classNames('d-inline-block', styles.arrow)}>
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        </Button>
      </div>
    </section>
  );
}

import classNames from 'classnames';
import Image from 'components/Image';
import Link from 'components/Link';
import ScrollArrows from 'components/ScrollArrows';
import codify from 'utils/codify';
import styles from './Discount2Up.module.scss';

export default function Discount2Up({
  discounts = [],
  overrideHeader,
  sectionContent,
  showButtons = true,
}) {
  const {
    lead_in: leadIn,
    header,
    subheader,
    cta_one: { title: ctaOneText, href: ctaOneHref },
    cta_two: { title: ctaTwoText, href: ctaTwoHref },
    default_image: defaultImage,
    url: baseUrl,
  } = sectionContent;

  const formattedHeader = codify(header);
  const containerId = `${formattedHeader}_discount_container`;
  const leftButtonId = `${formattedHeader}_left_button`;
  const rightButtonId = `${formattedHeader}_right_button`;

  return (
    <section className={classNames('mx-auto my-10 w-100', styles.section)}>
      <div className="d-flex flex-column flex-md-row justify-content-between mx-5">
        <div
          className={classNames(
            'd-flex flex-column justify-content-start my-auto pb-14 pb-md-0 px-3',
            styles.content_wrapper
          )}
        >
          <div className="font-acumin">
            {leadIn && (
              <p className="fw-900 text-primary fs-7 fs-xl-9 mb-2">{leadIn}</p>
            )}
            {header && (
              <h2 className="fw-900 text-primary fs-15 fs-xl-26 mb-2">
                {overrideHeader ?? header}
              </h2>
            )}
            {subheader && (
              <p className="fw-900 text-primary fs-7 fs-xl-9 mb-2">
                {subheader}
              </p>
            )}
          </div>
          {showButtons && (
            <div
              className={classNames(
                'd-flex flex-row mt-10',
                styles.cta_container
              )}
            >
              {ctaOneText && ctaOneHref && (
                <Link
                  href={ctaOneHref}
                  className={classNames(
                    'btn bg-white border-2 border-primary d-inline-block font-geo fs-7 fw-600 px-10 py-5 my-auto text-primary text-uppercase',
                    ctaTwoText ? 'w-50 me-4' : 'w-100'
                  )}
                >
                  {ctaOneText}
                </Link>
              )}
              {ctaTwoText && ctaTwoHref && (
                <Link
                  href={ctaTwoHref}
                  className={classNames(
                    'btn bg-white border-2 border-primary d-inline-block font-geo fs-7 fw-600 px-10 py-5 my-auto text-primary text-uppercase',
                    ctaOneText ? 'w-50 ms-4' : 'w-100'
                  )}
                >
                  {ctaTwoText}
                </Link>
              )}
            </div>
          )}
        </div>
        <div className={classNames(styles.discounts_wrapper)}>
          <ScrollArrows
            containerId={containerId}
            leftButtonId={leftButtonId}
            rightButtonId={rightButtonId}
            scrollBuffer={30}
            scrollIncrement={400}
          >
            <ul
              id={containerId}
              className={classNames(
                'list-unstyled mx-0',
                styles.discounts_container
              )}
            >
              {discounts.map((discount) => {
                const { id, onlineName, onlineNameOverride, image } = discount;
                const url = `${baseUrl}/${id}`;
                return (
                  <li
                    key={image?.alt ?? onlineName}
                    className={classNames(
                      'p-0',
                      discounts?.length === 1
                        ? styles.discount_item
                        : styles.multiple_discount_items
                    )}
                  >
                    <Link href={url}>
                      <div className="d-flex flex-column h-100 mx-2">
                        <div
                          className={classNames(
                            'w-100 mx-auto mt-1 position-relative',
                            styles.discount_image_container
                          )}
                        >
                          <div
                            className={classNames(
                              'h-100 w-100 position-absolute',
                              styles.discount_image_wrapper
                            )}
                          >
                            <Image
                              fill
                              legacy
                              className="mw-100 rounded"
                              objectFit="contain"
                              src={image?.url ?? defaultImage?.url}
                              alt={image?.alt ?? defaultImage?.alt}
                              sizes="(max-width: 992px) 50vw, 25vw"
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column w-100 my-5 font-acumin">
                          <p className="font-acumin fw-600 my-0 fs-7 ">
                            {onlineNameOverride === ''
                              ? onlineName
                              : onlineNameOverride}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </ScrollArrows>
        </div>
      </div>
    </section>
  );
}
